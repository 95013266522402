import styled from 'styled-components';

import { Link } from '@yoweb/ui/components/Link';
import { Label, Text } from '@yoweb/ui/components/typography';
import { getColor } from '@yoweb/ui/styles/utils/theme';

export type SourceLinkProps = {
  sourceLabel?: string;
  source?: string;
  sourceHref?: string;
};

export const SourceLink = ({ sourceLabel = 'source', source, sourceHref }: SourceLinkProps) =>
  source && sourceHref ? (
    <Source>
      {/* eslint-disable-next-line react/jsx-no-literals */}
      <Label variant="muted" size="xs">{`${sourceLabel}:`}</Label>
      <StyledLink href={sourceHref} hasUnderline>
        <Text variant="muted" weight="regular" size="sm">
          {source}
        </Text>
      </StyledLink>
    </Source>
  ) : null;

const Source = styled.div`
  display: grid;
  column-gap: 8px;
  grid-auto-flow: column;
  grid-template-columns: min-content;
  align-items: baseline;
`;

const StyledLink = styled(Link)`
  && {
    color: ${getColor('base700')};
  }
`;

export default SourceLink;
