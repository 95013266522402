import { type ReactNode, useEffect, useRef, useState, type MutableRefObject } from 'react';
import styled, { css } from 'styled-components';
import { animate, motion, useMotionValue } from 'framer-motion';

import { breakpoints, media, pxToRem } from '@yoweb/ui/styles/utils';
import { useInView } from '@yoweb/ui/components/AnimateIn';
import { Box } from '@yoweb/ui/components/Box';
import { getSpace } from '@yoweb/ui/styles/utils/theme';
import type { Width } from '@yoweb/ui/styles/utils/mixins.utils';

type CardCarouselProps = {
  id: string;
  cardGap?: number;
  children: ReactNode[];
  dataTestId?: string;
  /** Should move cards to the left when carousel comes into view */
  animatedIn?: boolean;
  /** In case this carousel is positioned absolutely, pass in a parent reference to better represent the containing width */
  externalParentRef?: MutableRefObject<HTMLDivElement | null>;
  onIsDragging?: (isDragging: boolean) => void;
  itemWidth?: Width['width'];
  /** Add some left padding to the carousel */
  hasLeftPadding?: boolean;
};

export const CardCarousel = ({
  id,
  cardGap = 24,
  animatedIn = true,
  children,
  dataTestId,
  externalParentRef,
  onIsDragging,
  itemWidth,
  hasLeftPadding,
}: CardCarouselProps) => {
  const [ref, isInView] = useInView();
  const x = useMotionValue(animatedIn ? 120 : 0);
  const parentRef = useRef<HTMLDivElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [width, setWidth] = useState(-1);
  const [isMouseOver, setMouseOver] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const onResize = () => {
      if (!contentRef.current || !parentRef.current) {
        return;
      }

      const parentWidth = externalParentRef?.current?.offsetWidth ?? parentRef.current.offsetWidth;
      const contentWidth = contentRef.current.offsetWidth;

      setWidth(Math.max(contentWidth - parentWidth, 0));
    };

    onResize();

    window.addEventListener('resize', onResize, { passive: true });

    return () => {
      x.stop();
      window.removeEventListener('resize', onResize);
    };
  }, [x, externalParentRef]);

  // Scroll behavior
  useEffect(() => {
    const onWheelMove = (event: globalThis.WheelEvent) => {
      // Don't steal y scroll
      if (!isMouseOver || event.deltaX === 0) {
        return;
      }

      x.stop();
      event.preventDefault();

      if (x.get() >= 0 && event.deltaX < 0) {
        x.set(0);
      } else if (x.get() <= -width && event.deltaX > 0) {
        x.set(-width);
      } else {
        x.set(x.get() - event.deltaX);
      }
    };

    window.addEventListener('wheel', onWheelMove, { passive: false });

    return () => {
      x.stop();
      window.removeEventListener('wheel', onWheelMove);
    };
  }, [x, width, isMouseOver]);

  useEffect(() => {
    if (!isInView || width < 0) {
      return;
    }

    const animateIn = () => {
      if (!isInView || width < 0) {
        return;
      }

      const duration = 1;

      void animate(x, 0, {
        duration,
        ease: 'easeOut',
      });
    };

    animateIn();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView, width]);

  useEffect(() => {
    if (animatedIn || !isInView || width < 0) {
      return;
    }
  }, [width, x, isInView, animatedIn, isMouseOver]);

  return (
    <CarouselContainer
      role="listbox"
      aria-roledescription="carousel"
      tabIndex={0}
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
    >
      <DragContainer ref={parentRef} $isDragging={isDragging} $leftPadding={hasLeftPadding}>
        <motion.div
          data-testid={dataTestId}
          ref={ref}
          drag="x"
          style={{ x }}
          onDrag={() => x.stop()}
          onDragEnd={() => {
            onIsDragging?.(false);
            setIsDragging(false);
          }}
          onDragStart={() => {
            onIsDragging?.(true);
            setIsDragging(true);
          }}
          dragConstraints={{
            left: -width,
            right: 0,
          }}
        >
          <Center>
            <CardsWrap data-testid="card-carousel-cardswrap" cardGap={cardGap} ref={contentRef}>
              {children?.map((card, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Item
                  data-testid={`card-carousel-item-${index}`}
                  key={`${id}-${index}`}
                  width={itemWidth}
                >
                  {card}
                </Item>
              ))}
            </CardsWrap>
          </Center>
        </motion.div>
      </DragContainer>
    </CarouselContainer>
  );
};

const CarouselContainer = styled.div`
  overflow: hidden;
`;

const DragContainer = styled(motion.div)<{ $isDragging: boolean; $leftPadding?: boolean }>`
  width: 100%;
  overflow: hidden;
  position: relative;
  transform: translateZ(0);
  cursor: ${({ $isDragging }) => ($isDragging ? 'grabbing' : 'grab')};

  ${({ $leftPadding }) =>
    $leftPadding &&
    css`
      padding-left: ${getSpace('normal1')};
    `}
`;

const Center = styled.div`
  display: flex;
`;

const CardsWrap = styled.div<{ cardGap: number }>`
  margin: 0;
  padding-right: ${getSpace('normal2')};
  display: flex;

  --card-gap: ${({ cardGap }) => pxToRem(cardGap)};
  --card-container: ${pxToRem(320)};
  --card-count: 1;
  --card-peek: ${pxToRem(4)};

  ${media.md`
    --card-peek: ${pxToRem(24)};
    --card-count: 2;
    --card-container: ${pxToRem(breakpoints.md)};
  `}

  ${media.lg`
    --card-peek: ${pxToRem(32)};
    --card-count: 3;
    --card-container: ${pxToRem(breakpoints.lg)};
  `}

  ${media.xl`
    --card-container: ${pxToRem(breakpoints.xl)};
  `}
`;

const Item = styled(Box)`
  && {
    display: flex;
    margin-left: calc(var(--card-gap) / 2);
    margin-right: calc(var(--card-gap) / 2);
    will-change: transform;

    ${({ width }) =>
      !width &&
      css`
        width: calc(
          (
            ((var(--card-container)) / var(--card-count, 1)) - var(--card-peek) /
              var(--card-count, 1)
          )
        );
      `}

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;
