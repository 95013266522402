import { useEffect, useRef, useState } from 'react';
import type { ReactNode, SetStateAction } from 'react';

import { clamp } from '@yoweb/utils/common';
import { useSwipeGesture } from '@yoweb/ui/hooks/useSwipeGesture';

export type CarouselSlide = ReactNode;

export type CarouselOptions = {
  loop?: boolean;
  autoPlay?: boolean;
  slideDuration?: number;
};

export type WithCarouselNavigation = {
  isActive: boolean;
  isPlaying: boolean;
  isPrevious: boolean;
  isFuture: boolean;
  isFarAway: boolean;
  isLastSlide: boolean;
  isFirstSlide: boolean;
  index: number;
  loop: boolean;
  autoPlay: boolean;
  slideDuration: number;
};

type CarouselHook = {
  slideIndex: number;
  showNextButton: boolean;
  next: () => void;
  showBackButton: boolean;
  previous: () => void;
  goTo: (index: number) => void;
  getRootProps: () => {
    onKeyUp: (event: { key: KeyboardEvent['key'] }) => void;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
  };
  getSliderProps: () => { ref(slider: HTMLElement | null): void };
  getSlideProps: (index: number) => { 'aria-hidden': undefined | boolean; tabIndex: number };
  getNavProps: (index: number) => WithCarouselNavigation & {
    onClick(): void;
  };
};

const useCarousel = (
  slides: ReadonlyArray<CarouselSlide>,
  { autoPlay = false, loop = false, slideDuration = 0 }: CarouselOptions,
): CarouselHook => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const sliderRef = useRef<HTMLElement | null>(null);
  const timeoutRef = useRef({
    id: 0,
    startTime: 0,
    remaining: 0,
    isHovering: false,
  });
  const lastIndex = slides.length - 1;
  const showNextButton = loop || slideIndex < lastIndex;
  const showBackButton = loop || slideIndex > 0;
  const clampRange = clamp.bind(null, 0, lastIndex);

  function stopTimer() {
    window.clearTimeout(timeoutRef.current.id);
  }

  function startTimer(duration = slideDuration) {
    if (!autoPlay) {
      return;
    }

    stopTimer();
    setIsPlaying(true);

    timeoutRef.current.startTime = Date.now();
    timeoutRef.current.id = window.setTimeout(next, duration);
  }

  const transition = (index: SetStateAction<number>) => {
    setSlideIndex(index);

    if (timeoutRef.current.isHovering) {
      return;
    }

    startTimer();
  };

  function goTo(index: number) {
    transition(clampRange(index));
  }

  function next() {
    transition((current) => {
      if (loop) {
        return clampRange((current + 1) % slides.length);
      }

      return clampRange(current + 1);
    });
  }

  function previous() {
    transition((current) => {
      if (loop && current === 0) {
        return lastIndex;
      }

      return clampRange(current - 1);
    });
  }

  useEffect(() => {
    if (!autoPlay) {
      return;
    }

    startTimer();
    return stopTimer;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoPlay]);

  useEffect(() => {
    sliderRef.current?.style.setProperty('--tx', `-${slideIndex * 100}%`);
  }, [slideIndex]);

  useSwipeGesture({
    element: sliderRef,
    mobileOnly: true,
    onSwipeLeft: previous,
    onSwipeRight: next,
  });

  return {
    slideIndex,
    showNextButton,
    showBackButton,
    next,
    previous,
    goTo,
    getRootProps: () => ({
      onKeyUp: ({ key }) => {
        switch (key) {
          case 'ArrowLeft':
            return previous();
          case 'ArrowRight':
            return next();
        }
      },
      onMouseEnter: () => {
        if (!autoPlay) {
          return;
        }

        const elapsed = Date.now() - timeoutRef.current.startTime;
        timeoutRef.current.remaining = slideDuration - elapsed;
        timeoutRef.current.isHovering = true;

        setIsPlaying(false);
        stopTimer();
      },
      onMouseLeave: () => {
        if (!autoPlay) {
          return;
        }

        timeoutRef.current.isHovering = false;
        startTimer(timeoutRef.current.remaining);
      },
    }),
    getSliderProps: () => ({
      ref(slider: HTMLElement | null) {
        sliderRef.current = slider;
      },
    }),
    getSlideProps: (index: number) => ({
      tabIndex: index === slideIndex ? 0 : -1,
      'aria-hidden': index === slideIndex ? undefined : true,
    }),
    getNavProps: (index: number) => ({
      isPrevious: slideIndex < index,
      isActive: slideIndex === index,
      isFuture: slideIndex > index,
      isFarAway: Math.abs(slideIndex - index) > 2,
      isLastSlide: index === lastIndex,
      isFirstSlide: index === 0,
      slideDuration,
      isPlaying,
      autoPlay,
      index,
      loop,
      ...(slideIndex === index && {
        tabIndex: -1,
        'aria-hidden': true,
      }),
      onClick: () => {
        goTo(index);
      },
    }),
  };
};

export default useCarousel;
