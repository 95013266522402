import { type MutableRefObject, useCallback, useEffect, useRef } from 'react';

function getX(event: TouchEvent | MouseEvent): number {
  if ('changedTouches' in event) {
    return event.changedTouches[0].clientX;
  }

  return event.clientX;
}

type SwipeGestureOptions = {
  element: MutableRefObject<HTMLElement | null>;
  threshold?: number;
  mobileOnly?: boolean;
  onSwipeLeft(): void;
  onSwipeRight(): void;
};

export const useSwipeGesture = ({
  element,
  onSwipeLeft,
  onSwipeRight,
  threshold = 75,
  mobileOnly = false,
}: SwipeGestureOptions): void => {
  const swipeRef = useRef<number>(0);

  const onDown = useCallback((event: TouchEvent | MouseEvent) => {
    swipeRef.current = getX(event);
  }, []);

  const onUp = useCallback(
    (event: TouchEvent | MouseEvent) => {
      const delta = swipeRef.current - getX(event);

      if (Math.abs(delta) < threshold) {
        return;
      }

      if (delta < 0) {
        onSwipeLeft();
      } else {
        onSwipeRight();
      }

      swipeRef.current = 0;
    },
    [onSwipeLeft, onSwipeRight, threshold],
  );

  useEffect(() => {
    const domElement = element.current;

    if (!mobileOnly) {
      domElement?.addEventListener('mousedown', onDown, { passive: true });
      domElement?.addEventListener('mouseup', onUp, { passive: true });
    }

    domElement?.addEventListener('touchstart', onDown, { passive: true });
    domElement?.addEventListener('touchend', onUp, { passive: true });

    return () => {
      if (!mobileOnly) {
        domElement?.removeEventListener('mousedown', onDown);
        domElement?.removeEventListener('mouseup', onUp);
      }

      domElement?.removeEventListener('touchstart', onDown);
      domElement?.removeEventListener('touchend', onUp);
    };
  }, [onDown, onUp, element, mobileOnly]);
};
