import IconBase, { type IconProps } from '@yoweb/ui/components/Icon/IconBase';

export const QuoteIcon = (props: IconProps) => (
  <IconBase viewBox="0 0 16 16" {...props}>
    <path
      d="M3.672 7.816c.874.07 1.591.403 2.15 1 .56.596.84 1.35.84 2.263 0 .982-.314 1.807-.944 2.474-.595.631-1.346.947-2.256.947-1.049 0-1.888-.386-2.518-1.158C.314 12.535 0 11.377 0 9.868c0-3.017 1.014-5.596 3.043-7.736.42-.421.856-.632 1.311-.632.455 0 .84.158 1.154.474.35.28.525.649.525 1.105 0 .35-.158.72-.472 1.105a7.143 7.143 0 0 0-1.889 3.632Zm9.338 0c.874.07 1.591.403 2.15 1 .56.596.84 1.35.84 2.263 0 .982-.315 1.807-.944 2.474-.595.631-1.347.947-2.256.947-1.05 0-1.889-.386-2.518-1.158-.63-.807-.944-1.965-.944-3.474 0-3.017 1.014-5.596 3.042-7.736.42-.421.857-.632 1.312-.632.454 0 .84.158 1.154.474.35.28.524.649.524 1.105 0 .35-.157.72-.472 1.105a7.143 7.143 0 0 0-1.888 3.632Z"
      fill="currentColor"
    />
  </IconBase>
);

export default QuoteIcon;
