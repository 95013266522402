import IconBase, { type IconProps } from '@yoweb/ui/components/Icon/IconBase';

export const CaretLeftIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.024 21.531a.75.75 0 0 1-1.06-.002l-8.995-9.027a.75.75 0 0 1 .001-1.06l9-8.973a.75.75 0 1 1 1.06 1.062l-8.47 8.444 8.466 8.496a.75.75 0 0 1-.002 1.06Z"
      fill="currentColor"
    />
  </IconBase>
);

export default CaretLeftIcon;
