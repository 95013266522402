import * as prismicHelpers from '@prismicio/helpers';

import { withPrismicGuardian } from '@yoweb/prismic-slicemachine/components/withPrismicGuardian';
import {
  type TestimonialSectionSliceResponse,
  requiredPrimaryFields,
  requiredItemsWithImagesFields,
  requiredItemsWithOutImagesFields,
} from './TestimonialSectionSliceTypes';
import { TestimonialSection } from '@yoweb/ui/components/marketing/TestimonialSection';

type TestimonialSectionSliceProps = {
  slice: TestimonialSectionSliceResponse;
} & DataTestId;

const TestimonialSectionSlice = ({ slice, ...rest }: TestimonialSectionSliceProps) => {
  const { title, smallPrint, navId } = slice.primary;

  const props = {
    title: prismicHelpers.asText(title) ?? '',
    disclaimer: smallPrint ? prismicHelpers.asText(smallPrint) : '',
    testimonials: slice.items.map(({ image, quote, author, authorTitle }) => ({
      image: image?.url ?? '',
      quote: prismicHelpers.asText(quote) ?? '',
      author: prismicHelpers.asText(author) ?? '',
      authorTitle,
    })),
    variation: slice.variation,
    navId,
  };

  return <TestimonialSection {...props} {...rest} />;
};

export default withPrismicGuardian(TestimonialSectionSlice, {
  type: 'variations',
  variations: {
    'default-slice': {
      primaryRequired: requiredPrimaryFields,
      itemsRequired: requiredItemsWithImagesFields,
    },
    testimonialsWithoutImage: {
      primaryRequired: requiredPrimaryFields,
      itemsRequired: requiredItemsWithOutImagesFields,
    },
  },
});
