import styled, { css, keyframes } from 'styled-components';

import { getColor, getDuration, getRadii } from '@yoweb/ui/styles/utils/theme';
import { pxToRem } from '@yoweb/ui/styles/utils';
import type { WithCarouselNavigation } from '@yoweb/ui/components/marketing/Carousel/useCarousel';

const frames = keyframes`
  0% {
    transform: translateX(calc(${pxToRem(6)} - 100%));
  }
  
  100% {
    transform: translateX(0);
  }
`;

const NavigationItem = styled.button<WithCarouselNavigation>`
  appearance: none;
  background: transparent;
  border: none;
  cursor: ${({ isActive }) => (isActive ? 'auto' : 'pointer')};
  display: inline-block;
  height: ${pxToRem(24)};
  overflow: hidden;
  padding: 0;
  position: relative;
  transition: width ${getDuration('interaction')}ms;
  width: ${({ isActive }) => pxToRem(isActive ? 32 : 16)};

  ${({ isFarAway }) =>
    isFarAway &&
    css`
      width: ${pxToRem(8)};
    `}

  &::before {
    background-color: ${getColor('baseA400')};
    border-radius: ${getRadii('tiny')};
    content: '';
    display: block;
    height: ${pxToRem(4)};
    left: 0;
    position: absolute;
    right: 0;
    top: calc(50% - ${pxToRem(2)});
  }

  &::after {
    background-color: ${getColor('base1000')};
    border-radius: ${getRadii('tiny')};
    content: '';
    display: block;
    height: ${pxToRem(4)};
    left: 0;
    position: absolute;
    right: 0;
    top: calc(50% - ${pxToRem(2)});
    transform: translateX(
      ${({ isActive, isPrevious }) =>
        // eslint-disable-next-line no-nested-ternary
        isActive ? 0 : isPrevious ? -100 : 100}%
    );

    ${({ isActive, isPlaying, slideDuration, autoPlay, isLastSlide, loop }) => {
      if (!isActive || !autoPlay) {
        return;
      }

      if (!loop && isLastSlide) {
        return css`
          animation: ${frames} ${getDuration('interaction')}ms linear forwards;
        `;
      }

      return css`
        animation: ${frames} ${slideDuration}ms linear forwards;
        animation-play-state: ${isPlaying ? 'running' : 'paused'};
      `;
    }}
  }
`;

export default NavigationItem;
