import styled from 'styled-components';
import type { FontSizes } from 'styled-components';

import { th } from '@yoweb/ui/styles/utils/theme';
import { textBase } from './textBase';
import { createSizeMixin, type WithSize } from '@yoweb/ui/styles/utils/mixins';
import type { TextBaseProps } from './textBase';

export type QuoteMarketingSize = 'xl' | 'lg';

type QuoteMarketingProps = WithSize<QuoteMarketingSize> & TextBaseProps;

/** Map interactive size to theme key. */
const sizeMapping: Record<QuoteMarketingSize, keyof FontSizes> = {
  lg: 'marketingQuoteLg',
  xl: 'marketingQuoteXl',
};

/**
 * Interactive size & line height mixin.
 */
const quoteMarketingSizeMixin = createSizeMixin<QuoteMarketingSize, QuoteMarketingProps>(
  ({ size = 'lg' as QuoteMarketingSize }, tagFn) =>
    tagFn`
    font-size: ${th.getFontSize(sizeMapping[size])};
    line-height: ${th.getLineHeight(sizeMapping[size])};
    letter-spacing: ${th.getLetterSpacing(sizeMapping[size])};
  `,
);

/**
 * Interactive text component with multiple props to modify.
 */
export const QuoteMarketing = styled.span<QuoteMarketingProps>`
  ${textBase};
  ${quoteMarketingSizeMixin};
`;

export { quoteMarketingSizeMixin };
