import styled, { css } from 'styled-components';

import { getColor, getDuration, getRadii, getSpace } from '@yoweb/ui/styles/utils/theme';
import { ButtonIcon } from '@yoweb/ui/components/buttons';
import { CaretLeftIcon } from '@yoweb/ui/components/Icon/CaretLeftIcon';
import { CaretRightIcon } from '@yoweb/ui/components/Icon/CaretRightIcon';
import { media, pxToRem } from '@yoweb/ui/styles/utils';
import NavigationItem from '@yoweb/ui/components/marketing/Carousel/NavigationItem';
import useCarousel from '@yoweb/ui/components/marketing/Carousel/useCarousel';
import { useTranslation } from '@yoweb/next-i18next';
import SourceLink, { type SourceLinkProps } from '@yoweb/ui/components/marketing/SourceLink';
import type {
  CarouselOptions,
  CarouselSlide,
} from '@yoweb/ui/components/marketing/Carousel/useCarousel';

export type MarketingCarouselProps = CarouselOptions &
  DataTestId & {
    slides: ReadonlyArray<CarouselSlide>;
    slideSource?: SourceLinkProps[];
  };

const MarketingCarousel = ({ slides, slideSource, ...options }: MarketingCarouselProps) => {
  const { t } = useTranslation<'common'>();
  const carousel = useCarousel(slides, options);
  const source = slideSource?.[carousel.slideIndex];
  const dataTestId = options['data-testid'];

  return (
    <div {...carousel.getRootProps()} {...(dataTestId ? { 'data-testid': dataTestId } : {})}>
      <Wrap>
        <Slides data-testid="marketing-carousel-slides" {...carousel.getSliderProps()}>
          {slides.map((slide, index) => (
            <Slide key={index} {...carousel.getSlideProps(index)}>
              {slide}
            </Slide>
          ))}
        </Slides>
        <LeftButton data-testid="marketing-carousel-left-button" isActive={carousel.showBackButton}>
          <ButtonIcon
            onClick={carousel.previous}
            size="sm"
            variant="tertiary"
            isDisabled={!carousel.showBackButton}
            aria-label={t('previous')}
            icon={<CaretLeftIcon />}
          />
        </LeftButton>
        <RightButton
          data-testid="marketing-carousel-right-button"
          isActive={carousel.showNextButton}
        >
          <ButtonIcon
            onClick={carousel.next}
            size="sm"
            variant="tertiary"
            isDisabled={!carousel.showNextButton}
            aria-label={t('next')}
            icon={<CaretRightIcon />}
          />
        </RightButton>
      </Wrap>
      <Bottom>
        {source && (
          <SourceLink
            source={source.source}
            sourceHref={source.sourceHref}
            sourceLabel={source.sourceLabel}
          />
        )}
        <NavigationWrap>
          {slides.map((_, index) => (
            <li key={index}>
              <NavigationItem {...carousel.getNavProps(index)} />
            </li>
          ))}
        </NavigationWrap>
      </Bottom>
    </div>
  );
};

const Bottom = styled.div`
  margin-top: ${pxToRem(20)};
  position: relative;
`;

const NavigationWrap = styled.ul`
  display: grid;
  grid-gap: ${pxToRem(4)};
  grid-auto-flow: column;
  justify-content: center;

  ${media.md`
    display: none;
  `}
`;

const Wrap = styled.div`
  background-color: ${getColor('base050')};
  border-radius: ${getRadii('small')};
  overflow: hidden;
  position: relative;
  min-height: 0;
  max-width: 100vw;
`;

type WithActiveState = {
  isActive: boolean;
};

const buttonStyle = css<WithActiveState>`
  display: none;
  opacity: 0;
  position: absolute;
  top: 50%;
  transition:
    transform ${getDuration('interaction')}ms,
    opacity ${getDuration('interaction')}ms,
    visibility 0s ${getDuration('interaction')}ms;
  visibility: hidden;

  ${media.md`
    display: block;
  `}

  ${({ isActive }) =>
    isActive &&
    css`
      opacity: 1;
      transform: translate3d(0, -50%, 0);
      transition-delay: 0s;
      visibility: visible;
    `}
`;

const hideInactive = ({ isActive }: WithActiveState) => ({
  tabIndex: isActive ? 0 : -1,
  'aria-hidden': isActive ? undefined : true,
});

const LeftButton = styled.div.attrs<WithActiveState>(hideInactive)`
  left: ${getSpace('normal2')};
  transform: translate3d(-200%, -50%, 0);

  ${buttonStyle};
`;

const RightButton = styled.div.attrs<WithActiveState>(hideInactive)`
  right: ${getSpace('normal2')};
  transform: translate3d(200%, -50%, 0);

  ${buttonStyle};
`;

const Slides = styled.ul`
  display: flex;
  transform: translate3d(var(--tx, 0), 0, 0);
  transition: transform ${getDuration('interaction')}ms;
`;

const Slide = styled.li`
  flex: 0 0 100%;
  width: 100%;
  display: flex;
  align-items: center;
`;

export default MarketingCarousel;
