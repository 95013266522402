import type { RichTextField, ImageField } from '@prismicio/types';

import type {
  ArrayToUnion,
  CreateRequiredFields,
  PrismicSlice,
} from '@yoweb/prismic-slicemachine/slices/slicesTypes';

export const TestimonialSectionSliceType = 'testimonial_section_slice';

export const requiredPrimaryFields = ['title'] as const; // Array of all required primary fields.
export const requiredItemsWithImagesFields = ['image', 'quote', 'author'] as const; // Array of all required items fields.
export const requiredItemsWithOutImagesFields = ['quote', 'author'] as const;

type Primary = {
  title: RichTextField;
  smallPrint?: RichTextField;
  navId?: string;
  navLabel?: string;
};

type Items = {
  image: ImageField;
  quote: RichTextField;
  author: RichTextField;
  authorTitle?: string;
};

type Default = PrismicSlice<
  typeof TestimonialSectionSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFields>, Primary>,
  CreateRequiredFields<ArrayToUnion<typeof requiredItemsWithImagesFields>, Items>
>;

type withoutImages = PrismicSlice<
  typeof TestimonialSectionSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFields>, Primary>,
  CreateRequiredFields<ArrayToUnion<typeof requiredItemsWithOutImagesFields>, Items>,
  'testimonialsWithoutImage'
>;

export type TestimonialSectionSliceResponse = Default | withoutImages;
