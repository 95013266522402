import styled, { css } from 'styled-components';
import Link from 'next/link';
import Image, { type StaticImageData } from 'next/legacy/image';
import type { ReactElement, ReactNode } from 'react';

import { Grid } from '@yoweb/ui/components/Grid';
import { QuoteMarketing } from '@yoweb/ui/components/typography';
import { media, pxToRem } from '@yoweb/ui/styles/utils';
import { getDuration, getSpace, getRadii, getColor, getZIndex } from '@yoweb/ui/styles/utils/theme';
import { isString } from '@yoweb/utils/isString';

export type ActionImageCardProps = {
  image?: StaticImageData | string;
  content?: string;
  icon?: ReactElement;
  href?: string;
  actions?: ReactNode | ReactNode[];
  label?: ReactNode | ReactNode[];
  imagePosition?: string;
};

export const ActionImageCard = ({
  image,
  content,
  icon,
  label,
  href,
  actions,
  imagePosition = 'center top',
}: ActionImageCardProps) => (
  <Card>
    {href && (
      <Link href={href} passHref legacyBehavior>
        {/* eslint-disable-next-line styled-components-a11y/anchor-has-content */}
        <LinkActiveBorder href={href} />
      </Link>
    )}
    <InnerWrap>
      <ImageWrap>
        {image && (
          <Image
            src={image}
            layout="fill"
            objectFit="cover"
            objectPosition={imagePosition}
            {...(!isString(image)
              ? { placeholder: 'blur' }
              : { placeholder: 'blur', blurDataURL: `${image}&blur=2000` })}
          />
        )}
      </ImageWrap>
      <ImageGap />
      <Content>
        <Grid gap="small2">
          {icon}
          <QuoteMarketing
            as="p"
            size={{
              _: 'lg',
              xl: 'xl',
            }}
          >
            {content}
          </QuoteMarketing>
        </Grid>
        <Footer hasActions={Boolean(actions)}>
          <div>{label}</div>
          {Boolean(actions) && <Actions>{actions}</Actions>}
        </Footer>
      </Content>
    </InnerWrap>
  </Card>
);

const InnerWrap = styled.div`
  border-radius: ${getRadii('large')};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: ${getSpace('small3')};
  position: relative;
  transform: translateZ(0);
`;

const Content = styled.div`
  background: ${getColor('base000')};
  border-radius: ${getRadii('medium')};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: ${pxToRem(232)};
  padding: ${getSpace('normal3')};
  position: relative;
  transform: translateZ(0);
  width: 100%;

  ${media.xl`
    min-height: ${pxToRem(272)};
  `}
`;

const ImageGap = styled.div`
  min-height: ${pxToRem(232)};

  ${media.xl`
    min-height: ${pxToRem(336)};
  `}
`;

const ImageWrap = styled.div`
  background: ${getColor('base500')};
  inset: 0;
  pointer-events: none;
  position: absolute;
  transition: transform ${getDuration('visual')}ms ease-out;
  will-change: transform;
`;

const Card = styled.article`
  box-sizing: border-box;
  display: flex;
  max-width: 392px;
  position: relative;
`;

const LinkActiveBorder = styled.a`
  inset: -${getSpace('small2')};
  box-sizing: content-box;
  border-radius: calc(${getSpace('small2')} + ${getRadii('large')});
  outline: none;
  position: absolute;
  z-index: ${getZIndex('layer')};

  &:hover,
  &:active,
  &:focus {
    & + ${/* sc-selector */ InnerWrap} > ${/* sc-selector */ ImageWrap} {
      transition-duration: ${({ theme }) => 8 * theme.transitionDuration.visual}ms;
    }
  }

  &:focus:not(:active) {
    border: 2px solid ${getColor('base1000')};
  }
`;

const Footer = styled.div<{ hasActions: boolean }>`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${getSpace('small3')};
  margin-top: auto;
  padding-top: ${getSpace('small3')};
  width: 100%;

  ${({ hasActions }) =>
    hasActions &&
    css`
      grid-template-columns: 1fr 1fr;
    `}
`;

const Actions = styled.div`
  place-content: center end;
  display: grid;
  grid-auto-flow: column;
  grid-gap: ${getSpace('small3')};
  white-space: nowrap;
`;
