import styled from 'styled-components';

import { Container } from '@yoweb/ui/components/containers';
import { Center } from '@yoweb/ui/components/Grid/MarketingGrid';
import { BodyHeading, BodyHeader } from '@yoweb/ui/components/marketing/MarketingTexts';
import { getSpace } from '@yoweb/ui/styles/utils/theme';
import { AnimateWhenInView } from '@yoweb/ui/components/AnimateIn';
import { Title } from '@yoweb/ui/components/typography';
import { media } from '@yoweb/ui/styles/utils';

type ParagraphProps = {
  text: string | ReactNode;
  title?: string | ReactNode | null;
  label?: string;
  subtitle?: string | ReactNode | null;
  variation: string;
};

export const Paragraph = ({ text, title, label, subtitle, variation }: ParagraphProps) => (
  <Container withGrid>
    <Center>
      {variation === 'withLabel' && label && (
        <AnimateWhenInView>
          <BodyHeader title={title} label={label} mb="normal2" />
        </AnimateWhenInView>
      )}
      {variation === 'default' && (
        <StyledTitle size={{ _: 'xs', md: 'sm' }} whiteSpace="pre-wrap">
          {title}
        </StyledTitle>
      )}
      {variation === 'withSubtitle' && (
        <>
          <Title size={{ _: 'sm', md: 'md' }} textAlign="center" whiteSpace="pre-wrap">
            {title}
          </Title>
          <StyledSubtitle size="xs" textAlign="center" weight="regular">
            {subtitle}
          </StyledSubtitle>
        </>
      )}
      <div>{text}</div>
    </Center>
  </Container>
);

const StyledTitle = styled(BodyHeading)`
  && {
    margin-bottom: ${getSpace('normal2')};
  }
`;

const StyledSubtitle = styled(Title)`
  ${media.md`
    margin-top: ${getSpace('small2')};
  `}
`;
