import styled from 'styled-components';

import { media } from '@yoweb/ui/styles/utils';
import { Box, type BoxProps } from '@yoweb/ui/components/Box';
import { gridColumnMixin, gridRowMixin } from '@yoweb/ui/styles/utils/mixins.utils';

export const Full = styled(Box)`
  && {
    grid-column: 1 / span 4;

    ${media.md`
      grid-column: 1 / span 8;
    `}

    ${media.lg`
      grid-column: 1 / span 12;
    `}
  }
`;

export const Center = styled(Box)`
  && {
    grid-column: 1 / span 4;
    max-width: 100%;

    ${media.md`
      grid-column: 2 / span 6;
    `}

    ${media.lg`
      grid-column: 4 / span 6;
    `}
  }
`;

type LeftProps = Partial<Pick<BoxProps, 'mt' | 'mb'>> & Partial<WithChildren>;

export const Left = ({ mt = { _: 'normal3', md: 'normal2' }, mb, children }: LeftProps) => (
  <StyledLeft mt={mt} mb={mb}>
    {children}
  </StyledLeft>
);

const StyledLeft = styled(Box)`
  && {
    position: relative;
    grid-column: 1 / span 4;

    ${media.md`
      grid-column: 2 / span 6;
    `}

    ${media.lg`
      grid-column: 1 / span 2;
      grid-row: 1;
    `}
  }
`;

type RightProps = Partial<Pick<BoxProps, 'mt'>> & WithChildren;

export const Right = ({ mt = { _: 'normal3', md: 'normal2' }, children }: RightProps) => (
  <StyledRight mt={mt}>{children}</StyledRight>
);

export const StyledRight = styled(Box)`
  && {
    position: relative;
    grid-column: 1 / span 4;

    ${media.md`
      grid-column: 2/ span 6;
    `}

    ${media.lg`
      grid-column: 11 / span 2;
      grid-row: 1;
    `}
  }
`;

export const GridItem = styled(Box)`
  && {
    ${gridColumnMixin}
    ${gridRowMixin}
  }
`;
