import * as prismicHelpers from '@prismicio/helpers';
import { PrismicRichText } from '@prismicio/react';

import { withPrismicGuardian } from '@yoweb/prismic-slicemachine/components/withPrismicGuardian';
import { type ParagraphSliceResponse, requiredPrimaryFields } from './ParagraphSliceTypes';
import { Paragraph } from '@yoweb/ui/components/sections/Paragraph';
import { paragraphBodyLinkSerializer } from '@yoweb/prismic-slicemachine/utils/serializers';

type ParagraphSliceProps = {
  slice: ParagraphSliceResponse;
};

const ParagraphSlice = ({ slice }: ParagraphSliceProps) => {
  const { text } = slice.primary;

  const commonProps = {
    text: <PrismicRichText field={text} components={paragraphBodyLinkSerializer} />,
    variation: slice.variation,
  };

  if (slice.variation === 'withLabel') {
    const props = {
      ...commonProps,
      title: (
        <PrismicRichText field={slice.primary.title} components={paragraphBodyLinkSerializer} />
      ),
      label: slice.primary.label,
    };

    return <Paragraph {...props} />;
  }

  if (slice.variation === 'withSubtitle') {
    const props = {
      ...commonProps,
      title: slice.primary.title,
      subtitle: prismicHelpers.asText(slice.primary.subtitle),
    };

    return <Paragraph {...props} />;
  }

  return <Paragraph {...commonProps} title={slice.primary?.title} />;
};

export default withPrismicGuardian(ParagraphSlice, {
  primaryRequired: requiredPrimaryFields,
});
