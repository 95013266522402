import styled from 'styled-components';
import type { StaticImageData } from 'next/legacy/image';

import { QuoteIcon } from '@yoweb/ui/components/Icon/QuoteIcon';
import { CardCarousel } from '@yoweb/ui/components/CardCarousel';
import { ActionImageCard } from '@yoweb/ui/components/marketing/ActionImageCard';
import { Title, Text, QuoteMarketing, Label } from '@yoweb/ui/components/typography';
import { media, pxToRem } from '@yoweb/ui/styles/utils';
import { getColor, getSpace, th } from '@yoweb/ui/styles/utils/theme';
import { useInView, StaggerElements } from '@yoweb/ui/components/AnimateIn';
import { Container } from '@yoweb/ui/components/containers';
import { Box } from '@yoweb/ui/components/Box';

export type Testimonials = {
  quote: string;
  link?: string;
  image: StaticImageData | string;
  author: string;
  authorTitle?: string;
  imagePosition?: string;
}[];

export type Variation = 'default' | 'default-slice' | 'testimonialsWithoutImage';

export type TestimonialSectionProps = {
  testimonials: Testimonials;
  title: string;
  disclaimer?: string;
  variation: Variation;
  navId?: string;
};

export const TestimonialSection = ({
  title,
  disclaimer,
  testimonials = [],
  variation,
  navId,
}: TestimonialSectionProps) => {
  const [ref, isInView] = useInView({ rootMargin: '-100px 0px' });

  return (
    <Section
      mb={!disclaimer ? { _: 'large1' } : {}}
      id={navId}
      ref={ref}
      data-testid="testimonial-section"
    >
      <StaggerElements isInView={isInView}>
        <Header>
          <Title
            size={{ _: 'md', md: 'lg', lg: 'xl' }}
            textAlign="center"
            data-testid="testimonials-title"
          >
            {title}
          </Title>
        </Header>
      </StaggerElements>
      <CarouselWrapper leftMargin={variation === 'testimonialsWithoutImage'}>
        <CardCarousel dataTestId="testimonial-carousel" id="testimonial-carousel">
          {testimonials.map(({ quote, link, image, author, authorTitle, imagePosition }) => {
            if (variation === 'default-slice') {
              return (
                <ActionImageCard
                  key={author}
                  image={image}
                  imagePosition={imagePosition}
                  content={quote}
                  href={link}
                  icon={
                    <IconWrap>
                      <QuoteIcon />
                    </IconWrap>
                  }
                  label={
                    <>
                      <Text
                        lineHeight="bodyXl"
                        size="sm"
                        $textTransform="uppercase"
                        variant="muted"
                        weight="bold"
                      >
                        {author}
                      </Text>
                      <Text size="sm" variant="muted">
                        {authorTitle}
                      </Text>
                    </>
                  }
                />
              );
            } else if (variation === 'testimonialsWithoutImage') {
              return (
                <TestimonialCard key={author}>
                  <QuoteIcon />
                  <QuoteMarketing size={{ _: 'lg', lg: 'xl' }}>{quote}</QuoteMarketing>
                  <Label size="md" weight="regular" variant="muted">
                    {author}
                  </Label>
                </TestimonialCard>
              );
            }
          })}
        </CardCarousel>
      </CarouselWrapper>
      {disclaimer && variation === 'default-slice' && (
        <Box py={{ _: 20, md: 'normal2' }}>
          <Container>
            <Text
              variant="muted2"
              size={{ _: 'xs', md: 'sm' }}
              data-testid="testimonials-disclaimer"
            >
              {disclaimer}
            </Text>
          </Container>
        </Box>
      )}
    </Section>
  );
};

const Section = styled(Box)`
  overflow: hidden;
  padding-top: ${pxToRem(110)};

  ${media.lg`
    padding-top: ${getSpace('xl3')};
  `}
`;

const IconWrap = styled.div`
  color: ${getColor('coral')};
`;

const Header = styled.header`
  max-width: ${pxToRem(360)};
  margin-bottom: ${getSpace('normal3')};
  margin-left: auto;
  margin-right: auto;
  padding: ${getSpace('normal1')};

  ${media.md`
    margin-bottom: ${getSpace('large1')};
    max-width: ${pxToRem(650)};
  `}
`;

const TestimonialCard = styled.div`
  display: grid;
  grid-template-rows: min-content 1fr min-content;
  height: 384px;
  width: 327px;
  background-color: ${th.getColor('sand')};
  border-radius: ${th.getRadii('large')};
  padding: ${th.getSpace('normal3')};
  grid-gap: ${th.getSpace('small3')};
  align-content: start;
  ${media.lg`
    height: 434px;
    width: 392px;
  `}
`;

const CarouselWrapper = styled.div<{ leftMargin: boolean }>`
  margin-left: ${({ leftMargin = true }) => (leftMargin ? getSpace('normal4') : null)};
`;
