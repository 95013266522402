import type { RichTextField } from '@prismicio/types';

import type {
  ArrayToUnion,
  CreateRequiredFields,
  PrismicSlice,
} from '@yoweb/prismic-slicemachine/slices/slicesTypes';

export const ParagraphSliceType = 'paragraph_slice';

// Required fields in slice
export const commonRequiredPrimaryFields = ['text'] as const; // Array of all required primary fields.
export const requiredPrimaryFields = [...commonRequiredPrimaryFields] as const; // Array of all required primary fields.
export const requiredPrimaryFieldsWithLabel = [
  ...commonRequiredPrimaryFields,
  'title',
  'label',
] as const; // Array of all required primary fields.
export const requiredPrimaryFieldsWithSubtitle = [
  ...commonRequiredPrimaryFields,
  'title',
  'subtitle',
] as const; // Array of all required primary fields.

type CommonPrimary = {
  text: RichTextField;
};

type Primary = {
  title?: string;
} & CommonPrimary;

type PrimaryWithLabel = {
  title: RichTextField;
  label: string;
} & CommonPrimary;

type PrimaryWithSubtitle = {
  title: string;
  subtitle: RichTextField;
} & CommonPrimary;

export type DefaultSliceResponse = PrismicSlice<
  typeof ParagraphSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFields>, Primary>,
  null,
  'default'
>;

export type WithLabelSliceResponse = PrismicSlice<
  typeof ParagraphSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFieldsWithLabel>, PrimaryWithLabel>,
  null,
  'withLabel'
>;

export type WithSubtitleSliceResponse = PrismicSlice<
  typeof ParagraphSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFieldsWithSubtitle>, PrimaryWithSubtitle>,
  null,
  'withSubtitle'
>;

export type ParagraphSliceResponse =
  | DefaultSliceResponse
  | WithLabelSliceResponse
  | WithSubtitleSliceResponse;
